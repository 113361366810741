import {createContext, forwardRef, useContext} from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import {LayoutGroup, m} from 'framer-motion';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import TextButton from '../text-button';
import {
	tabIndicatorStyles,
	tabsContentStyles,
	tabsListStyles,
	tabsStyles,
	tabsTriggerButtonStyles,
	tabsTriggerStyles,
} from './styles';
import type {TextButtonProps} from '../text-button';

export type TabListProps = TabsPrimitive.TabsListProps & {flush?: boolean};
const TabsList = forwardRef<HTMLDivElement, TabListProps>(({flush, className, ...props}, forwardedRef) => (
	<TabsPrimitive.List {...props} className={twMerge(tabsListStyles({flush}), className)} ref={forwardedRef} />
));

const TabsContext = createContext<{value?: string}>({value: undefined});

export type TabsTriggerProps = Omit<TabsPrimitive.TabsTriggerProps, 'asChild'> & TextButtonProps;
const TabsTrigger = forwardRef<HTMLButtonElement, TabsTriggerProps>(
	({children, value, ...props}, forwardedRef) => {
		const {value: activeValue} = useContext(TabsContext);

		return (
			<LayoutGroup>
				<div className={tabsTriggerStyles()}>
					<TabsPrimitive.Trigger asChild {...props} value={value}>
						<TextButton className={tabsTriggerButtonStyles()} ref={forwardedRef}>
							{value === activeValue && (
								<m.span
									className={tabIndicatorStyles()}
									layoutId="tab-indicator"
									transition={{type: 'spring', bounce: 0, duration: 0.4}}
								/>
							)}
							<div className="z-10 flex items-center justify-center gap-1">{children}</div>
						</TextButton>
					</TabsPrimitive.Trigger>
				</div>
			</LayoutGroup>
		);
	},
);

export type TabsContentProps = TabsPrimitive.TabsContentProps;
const TabsContent = forwardRef<HTMLDivElement, TabsContentProps>(({className, ...props}, forwardedRef) => (
	<TabsPrimitive.Content {...props} className={twMerge(tabsContentStyles(), className)} ref={forwardedRef} />
));

export type TabsProps = TabsPrimitive.TabsProps;
const Tabs = assign(
	forwardRef<HTMLDivElement, TabsProps>(
		({orientation, value, defaultValue, className, ...props}, forwardedRef) => (
			<TabsContext.Provider value={{value: value || defaultValue}}>
				<TabsPrimitive.Root
					{...props}
					className={twMerge(tabsStyles({orientation}), className)}
					orientation={orientation}
					ref={forwardedRef}
					value={value}
				/>
			</TabsContext.Provider>
		),
	),
	{List: TabsList, Trigger: TabsTrigger, Content: TabsContent},
);

Tabs.displayName = 'Tabs';
Tabs.List.displayName = 'Tabs.List';
Tabs.Trigger.displayName = 'Tabs.Trigger';
Tabs.Content.displayName = 'Tabs.Content';

export default Tabs;
